.index-large-title {
    text-align: left;
    font-size: 1.6em;
    margin-left: 0;
    font-weight: 300;
    line-height: 1.1;
}

.index-subtitle {
    width: 98%;
    text-align:center;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.7em;
    font-weight: 400;
}

.index-features-list {
    list-style: none;
    margin-bottom: 50px;
    padding-left: 10px;
}

.index-features-list li {
    font-size: 0.7em;
    font-weight: 400;
    margin-bottom: 25px;
    color: #2f3330;
}

.index-description h3 {
    font-size: 1.1em;
    font-weight: 400;
    text-decoration: underline;
    color: #171717;
    margin-bottom:0;
}

.index-description p {
    font-size: 0.85em;
    color: #666666;
}

.index-description p b {
    color: #222222;
}

.index-description p a {
    color: #222222;
    text-decoration: underline;
}

.index-description-read-more {
    color: #000000 !important;
    font-size: 0.5em;
    font-weight: 700;
}

.index-description-read-more:hover {
    color: #000000 !important;
    text-decoration: underline;
}

@media (min-width: 600px) {

}

@media (min-width: 768px) {
    .index-large-title {
        font-size: 2em;
    }

    .index-subtitle {
        font-size: 0.85em;
    }

    .index-features-list {
        padding-left: 40px;
    }

    .index-features-list li {
        font-size: 0.8em;
    }
}

@media (min-width: 1110px) {
    .index-large-title {
        font-size: 3.8em;
        width: 90%;
    }
}