.token-title {
    margin: 0 0 25px;
}

.token-page-image-container, .token-page-image-container-2 {
    margin-bottom: 20px;
}

.token-page-image-container iframe, .token-page-image-container-2 iframe {
    border: none;
    width: 300px;
    height: 300px;
}

.token-page-traits {
    display: grid;
    margin-bottom: 20px;
    grid-template-columns: 1fr;
}

.token-page-traits-item {
    display: block;
    text-transform: capitalize;
    margin-bottom: 4px;
    
}

.token-page-traits-item span {
    margin-left: 5px;
    font-weight: 100;
    color: var(--accentColor6);
}

.token-page-bottom-info {
    display: block;
    width: 100%;
    margin-bottom: 8px;
}

.token-page-bottom-info a {
    margin-left: 5px;
}

.icb-container-buy-address {
    margin: 30px auto;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.85em;
}

.token-page-prev, .token-page-next {
    display: inline-block;
    width: 49%;
}

@media (min-width: 600px) {
    .token-page-image-container iframe {
        border: none;
        width: 590px;
        height: 590px;
    }

    .token-page-image-container-2 iframe {
        border: none;
        width: 100%;
        height: 590px;
    }

    .token-page-traits {
        grid-template-columns: 1fr 1fr;
    }

    .icb-container-buy-address {
        width: 590px;
    }
}

@media (min-width: 900px) {
    .token-title {
        margin: 25px auto 35px;
    }

    .token-page-traits {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .token-page-traits {
        margin: 35px 0;
    }

    .icb-container-buy-address {
        width: 900px;
    }
}

@media (min-width: 1100px) {
    .token-page-image-container-2 iframe {
        height: 700px;
    }
}