.info-page-container h1 {
    text-align: center;
    font-weight:300;
    margin-bottom: 20px;
}

.info-page-container h4 {
    text-align: center;
    font-weight:300;
    margin-top: 10px;
}

.info-page-container p {
    font-size: 0.85em;
}

.info-page-container p b {
    font-weight:400;
}