.tokens_list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
    column-gap: 10px;
}

.tokens_list_item {
    text-align: center;
    vertical-align: top;
    font-size: 0.85em;
}

@media (min-width: 600px) {
    .tokens_list {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (min-width: 900px) {
    .tokens_list {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}