.team_list_item {
    margin-bottom: 50px;
}

.team_list_title {
    display: block;
    width: 100%;
    text-align: center;
}

.team_list_title a {
    cursor: pointer;
}

.team_list_photo {
    width: 200px;
    height: auto;
    border-radius: 50%;
}

.team_list_text {
    display: block;
    width: 100%;
    font-size: 0.85em;
}

.team_list_name {
    font-weight: 400;
    color: #202020;
}

.team_list_title a:hover .team_list_name{
    color: var(--accentColor6);
}

.team_list_role {
    font-size: 0.8em;
    color: #505050;
}

@media (min-width: 768px) {
    .team_list_title {
        display: inline-block;
        width: 260px;
        vertical-align: top;
    }

    .team_list_text {
        display: inline-block;
        width: calc(100% - 270px);
        vertical-align: top;
        font-size: 0.75em;
    }

    .first_p {
        margin-top: 0;
    }

    .team_list_photo {
        width: 160px;
    }
}

@media (min-width: 1100px) {
    .team_list_item {
        margin: 60px 0 50px;
    }
}