.metamask-msg-error, .redBalance, .redGas {
    color: red;
    font-weight: 400;
}

.greenGas, .metamask-msg-success {
    color: #04b500;
    font-weight: 400;
}

.metamask-msg-normal {
    font-weight: 400;
}

.big-error {
    font-weight: 600;
    font-size: 1.1em;
    margin: 40px auto;
}

.metamask-msg-error, .metamask-msg-success, .metamask-msg-normal {
    margin-bottom: 20px;
}

.icb-container-input {
    width: 30px;
    font-weight: 400;
    font-size: 1em;
    border: 1px solid #888888;
    border-radius: 5px;
    padding: 7px 20px;
    text-align: center;
}

.icb-container-input-disable {
    width: 30px;
    padding: 7px 20px;
    font-weight: 400;
    font-size: 1em;
    border: 1px solid #888888;
    margin:0 auto;
    border-radius: 5px;
    color: #888888;
}

.icb-container-max-label {
    font-size: 0.7em;
    margin: 4px auto 11px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.icb-container-email-input {
    font-weight: 400;
    font-size: 1em;
    border: 1px solid #888888;
    border-radius: 5px;
    padding: 7px 10px;
    margin-bottom: 15px;
}

.icb-container-email-input-disable {
    font-weight: 400;
    font-size: 1em;
    padding: 7px 10px;
    margin-bottom: 15px;
}

.icb-container-button {
    display: inline-block;

    margin: 0 auto 20px ;
    padding: 7px 20px;
    background-color: var(--accentColor6);
    border-radius: 5px;
    border: none !important;
    box-shadow: 0 0 3px var(--accentColor6);
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;    

    color: #ffffff;
    font-weight: 400;
    font-size: 1em;
    text-decoration: none;

    cursor: pointer;
}

.icb-container-button:hover {
    border: none !important;
    background-color: var(--accentColor7);
}

.icb-container-button:visited {
    color: #ffffff;
    background-color: var(--accentColor6);
}

.icb-container-button-disable, .icb-container-button-disable:visited, .icb-container-button-disable:hover {
    border: 1px solid #4f4f4f;
    background-color: #cccccc !important;
    color: #000000;
    cursor:default;
    box-shadow: none;
}

.icb-container-buttons .icb-container-button {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 20px;
}

.token-minted-counter {
    font-size: 0.9em;
    font-weight: 500;
    color: #333;
    margin: 20px auto 20px;
}

.token-payment-methods-item {
    display: block;
    padding: 20px;

    border: 6px solid var(--accentColor6);
    margin: 0 0 20px;
}

.token-payment-methods-item-title {
    font-weight: 300;
    font-size: 1.4em;
}

.token-payment-gas-price {
    margin-top: 5px;
    font-size: 0.7em;
}

.icb-container-top-total {
    font-size: 1.1em;
    margin-bottom: 20px;
}

.icb-container-buy-address {
    color: #333;
}

.icb-container-top-total span, .icb-container-you-have span, .icb-container-buy-address span {
    font-weight: 400;
}

.icb-container-invalid-email-error {
    color: red;
    font-size: 0.85em;
    margin-bottom: 13px;
    font-weight: 400;
}

.icb-container-payment-description {
    display: block;
    margin-top: 10px;
    font-size: 0.70em;
}

.token-payment-methods-item-disable {
    display: block;
    padding: 50px 20px;

    border: 6px solid #888888;
    margin: 0 0 20px;

    /* width: 90%; */
    /* margin: 30px auto; */
    line-height: 1.4;
    color: #888888;
}

h1 a {
    text-decoration: underline;
}

h1 a:hover {
    text-decoration: none;
}

.icb-container-drop-title {
    font-size: 1.5em;
    font-weight: 600;
    color: var(--accentColor6);
}

.icb-container-drop-subtitle {
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .token-payment-methods {
        margin: 40px auto;
    }

    .token-payment-methods-item {
        display: inline-block;
        width: calc(50% - 140px);
        margin: 0 15px 10px;
        vertical-align: top;
        padding: 20px 40px;

        border: 8px solid var(--accentColor6);
    }

    .token-payment-methods-item-disable {
        display: inline-block;
        width: calc(50% - 140px);
        margin: 0 15px 10px;
        vertical-align: top;
        padding: 50px 40px;

        border: 8px solid #888;
    }

    .icb-container-drop-title {
        font-size: 2.6em;
    }
    
    .icb-container-drop-subtitle {
        font-size: 1.2em;
    }

    .icb-container-top-total {
        font-size: 1.3em;
    }
}

@media (min-width: 1300px) {
    .icb-container-payment-description {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .token-payment-methods-item-disable {
        margin: 40px auto;
    }

    .icb-container-drop-title {
        font-size: 3em;
        margin-top: 50px;
    }
    
    .icb-container-drop-subtitle {
        font-size: 1.3em;
        margin-bottom: 50px;
    }
}