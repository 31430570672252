.error-page {
    text-align: center;
    margin: 60px 0 120px;
}

.error-page-title {
    color: var(--accentColor6);
    font-size: 6em;
}

.error-page-text {
    font-size: 2em;
}

@media (min-width: 1100px) {
    .error-page {
        margin: 200px 0;
    }
}