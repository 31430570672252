.dropslist_item {
    display: block;
    width: 100%;
    margin-bottom: 40px;
}

.dropslist_item_info, .dropslist_item_image {
    display: block;
    width: 100%;
}

.dropslist_item_info {
    text-align: left;
    margin-bottom: 15px;
}

.dropslist_item_info_label {
    display: block;
    width: 99px;
    background-color: #e7fd49;
    padding: 5px 8px 4px 10px;
    font-size: 0.9em;
    margin-bottom: 13px;
}

.dropslist_item_info_title {
    color: #000000 !important;
    font-weight: 500;
    text-transform: uppercase;
}

.dropslist_item_info_title:hover {
    text-decoration: underline;
}

.dropslist_item_info_author {
    font-size: 0.9em;
    font-weight: 400;
    margin-bottom: 13px;
}

.dropslist_item_info_description {
    font-size: 0.80em;
    font-weight: 400;
    color: #636363;
}

.dropslist_item_info_link {
    font-size: 0.75em;
    font-weight: 400;
}

.dropslist_item_image {
    text-align: center;
}

.dropslist_item_image a img {
    width: 400px;
    max-width: 100%;
    height: auto;
}

@media (min-width: 768px) {
    .dropslist_item_info, .dropslist_item_image {
        display: inline-block;
        vertical-align: top;
    }

    .dropslist_item_info {
        width: calc(100% - 270px);
        margin-right: 20px;
        text-align: right;
    }

    .dropslist_item_image {
        width: 250px;
    }

    .dropslist_item_info_label {
        width: 113px;
        margin-right: 0;
        margin-left: auto;
        margin-top: 10px;
    }
}

@media (min-width: 1100px) {
    .dropslist {
        margin-top: 50px;
    }

    .dropslist_item_info {
        width: calc(100% - 470px);
    }

    .dropslist_item_image {
        width: 450px;
    }

    .dropslist_item_image a img {
        width: 450px;
    }

    .dropslist_item_info_label {
        width: 125px;
        font-size: 1em;
    }

    .dropslist_item_info_title {
        font-size: 2em;
        margin-bottom: 5px;
    }

    .dropslist_item_info_author {
        font-size: 1.1em;
        margin-bottom: 25px;
    }

    .dropslist_item_info_description {
        font-size: 0.95em;
        margin-bottom: 10px;
    }

    .dropslist_item_info_link {
        font-size: 0.85em;
    }
}