:root {
	--accentColor1: #00f0f5;
	--accentColor2: #eb6cd0;
    --accentColor3: #5db600;
    --accentColor4: #008c82;
    --accentColor5: #00d126;

    --accentColor6: #ff4b1f;
    --accentColor7: #fe6832;
    
	--errorColor: rgb(255, 0, 0);
	--sucsessColor: rgb(0, 180, 0);
    
    --twColor1: #1eabf1;
	--twColor2: #1c9bdb;
	--dsColor1: #5165ca;
	--dsColor2: #4458b8;
	--tgColor1: #1b9de2;
	--tgColor2: #1983bd;
	--rdColor1: #fd5830;
	--rdColor2: #c74221;
	--inColor1: #c63daa;
	--inColor2: #9c2083;
}

html {
	display: block;
}

a, a:hover, a:visited {
	display: inline-block;
	color: #ffffff;

}

a, a:visited {
	color: var(--accentColor6);
	text-decoration: none;
}

a:hover {
	color: var(--accentColor7);
}

h1, h2, h3 {
	font-weight: 300;
}

body {
	height: 100vh;
	width: 100vw;
	margin: 0;
	padding: 0;
	/* background: #000000; */
	/* background: #001233; */
	background: #ffffff;
	color: #000000;
	
	font-family: "Helvetica Neue","Helvetica","Arial",  sans-serif;
  	font-size: 1.4em;
	font-weight: 300;
}

main {
	text-align: center;
	max-width: 900px;
	margin: 0 auto ;
}

header, footer {
    text-align: center;
    max-width: 1100px;
    margin: 0 auto;
}

footer {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr;
}

#content {
	padding: 20px;
}

.footer-social-list {
    display: block;
    padding-bottom: 20px;
}

.footer-social-list span {
	display: none;
}

.footer-social-list a {
	margin: 0 10px 5px;
    color: #000000;
    border-bottom: 2px solid #ffffff;
}

.footer-social-list a:hover {
    
    border-bottom: 2px solid #000000;
}

.footer_copyright {
    font-size: 0.75em;
    padding-bottom: 20px;
}

.menu a {
	display:inline-block;
	font-size: 0.85em;
	margin: 0 10px;
    color: #000000;
    /* font-weight: 400; */
    border-bottom: 2px solid #ffffff;
    padding-bottom: 3px;
}

.menu a:hover {
    border-bottom: 2px solid #000000;
}

.menu, .header-icons {
    display: block;
    text-align: center;
    margin: 10px auto;
    padding: 0 15px;
}

.menu {
    margin: 20px auto 10px;
}

#logo {
    width: 100%;
    color: #000000;
    vertical-align: top;
    margin-top: 20px;
}

#logo img, #logo span {
    display: inline-block;
    vertical-align: top;
}

#logo img {
    width: 50px;
    height: 50px;
    max-width: 100%;
    margin-right: 12px;
}

#logo span {
    font-size: 1.4em;
    margin-top: 5px;
}

.header-icons a {
	display: inline-block;
	width: 18px;
	height: 18px;
	padding:5px;
	margin: 0 2px;
    cursor: pointer;
}

.header-icons a svg  {
	display: block;
	width: 100%;
	height: auto;
	fill: #000000;
}

.hs-tw:hover svg{
	fill: var(--twColor1);
}

.hs-ds:hover svg{
	fill: var(--dsColor1);
}

.hs-tg:hover svg{
	fill: var(--tgColor1);
}

.hs-in:hover svg{
	fill: var(--inColor1);
}

.buy-button-container {
    display: block;
    width: 100%;
    text-align: center;
}

.buy-button {
    display: inline-block;
    background-color: var(--accentColor6);
    border-radius: 10px;
    color: #ffffff !important;
    cursor: pointer;

    font-size: 1.2em;
    /* font-weight: 400; */
    padding: 6px 30px;
}

.buy-button:hover {
    background-color: var(--accentColor7);
}

.token-minted-counter {
    margin-bottom: 10px;
    font-size: 1.1em;
    
}

.token-minted-counter span {
    font-weight: 400;
    color: var(--accentColor6);
}

.roadmap-list {
    text-align: left;
}

.roadmap-list-item {
    margin-bottom: 6px;
}

.roadmap-list-item-perc, .roadmap-list-item-text {
    display: inline-block;
    vertical-align: top;
}

.roadmap-list-item-perc {
    /* font-weight: 400; */
    width: 80px;
    color: var(--accentColor6);
    text-align: center;
}

.roadmap-list-item-text {
    width: calc(100% - 85px);
}

.title-with-margin {
    margin-top:50px;
}

.team-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 20px auto;
    column-gap: 40px;
}

.team-container-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
}

.team-container-list-item {
    vertical-align: top;
    font-size: 0.85em;
}

.team-container-list-item img {
    width: 95%;
    height: auto;
    border-radius: 50%;
    margin: 0 auto 5px;
}

section {
    font-size: 1em;
    text-align: left;
}

section h1, section h2 {
    font-weight: 600;
}

section h1 {
    font-size: 1.9em;
}

section img, section iframe {
    max-width: 100%;
}

section h2 span {
display: inline-block;
    padding-right: 7px;
    background-image: linear-gradient(45deg, #ff4b1f, #ff9068);
    font-style: italic;
    font-weight: 400;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.center_content {
    text-align: center;
    margin: 0 auto;
}

.artists_section {
    margin: 60px 0 20px;
}

.drop-header {
    display: block;
    width: 100%;
    margin: 25px 0;
}

.drop-header-title {
    font-weight: 600;
}

.drop-header-subtitle, .drop-header-description {
    font-weight: 400;
}

.drop-header-description {
    font-size: 0.9em;
}

.drop-social-list {
    text-align: center;
    margin-bottom: 30px;
}

.drop-social-list a, .drop-social-list a svg, .drop-social-list a img, .drop-social-list a span {
    display: inline-block;
}

.drop-social-list a {
    margin: 3px 10px;
}

.drop-social-list a svg, .drop-social-list a img {
    margin-right: 8px;
    width: 16px;
    height: auto;
}

.drop-social-list a span {
    color: #000000 !important;
    text-decoration: underline;
}

.drop-social-list a:hover span {
    text-decoration: none;
}

.drop-mintinfo-1, .drop-mintinfo-2, .drop-mintinfo-3 {
    display: block;
    width: 100%;
}

.drop-mintinfo-1 {
    font-weight: 600;
}

.drop-mintinfo-1 span {
    color:var(--accentColor6);
}

.drop-mintinfo-2 {
    font-weight: 400;
    font-size: 0.9em;
}

.drop-mintinfo-2 a {
    text-decoration: underline;
}

.drop-mintinfo-2 a:hover {
    text-decoration: none;
}

.drop-mintinfo-2 a svg {
    width: 20px;
    height: 20px;
    margin-right: 7px;
    fill: var(--dsColor1);
}

.drop-mintinfo-2 a:hover svg {
    fill: var(--dsColor2);
}

.drop-mintinfo-3 {
    text-align: center;
}

.drop-mintinfo-button {
    color: #000000;
}

.drop-introduction {
    padding-left: 20px;
    border-left: 3px solid var(--accentColor6);
    font-weight: 400;
}

.drop-mint-button {
    color: #000000 !important;
    background-color: #fdfb2e;

    padding: 20px 40px;
    border-radius: 10px;

    font-weight: 400;

    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px 0px;
}

.drop-mint-button:hover {
    color: #ffffff !important;
    background-color: #ff7200;
}

.drop-2-title {
    display: block;
    width: 100%;
    height: 300px;

    background-image: url('./assets/images/drop2-title-img.jpg');
	background-position: top;
    background-size: 777px 300px;
}

@media (min-width: 375px) {
	.header-icons {
	margin: 7px auto 3px auto;
	}
}

@media (min-width: 600px) {
	#content span {
	display: inline-block;
	}

    .footer-social-list {
        padding-bottom: 0;
    }

    .footer_copyright {
        margin-top: 10px;
    }

    .drop-mintinfo-1, .drop-mintinfo-2 {
        display: inline-block;
        width: 49%;
        vertical-align: top;
    }

    .drop-mintinfo-2 {
        text-align: right;
    }
}

@media (min-width: 768px) {
	h1 {
		margin: 40px auto 30px;
		font-weight: 300;
	}

	#logo {
		margin-top: 10px;
		display: block;
        width: 230px;
        margin-bottom: 11px;
        font-size: 1.4em;
        text-align: left;
        margin-left: 20px;
	}

    #logo, .menu, .header-icons {
        display: inline-block;
        vertical-align: top;
    }
        
    #logo span{
        font-size: 1em;
        margin-top: 6px;
    }
        
    .menu {
        width: calc(100% - 410px);
        text-align: right;
        padding-top: 5px;
        /* padding-bottom: 5px; */
        margin-top: 14px;
        font-size: 1.1em;
    }
        
    .header-icons {
        width: 100px;
        padding-top: 16px;
    }

    .team-container {
        grid-template-columns: 1fr 1fr;
    }

    section {
        font-size: 1.15em;
    }

    .artists_section {
        margin: 80px 0 40px;
    }

    section h1 {
        font-size: 2.2em;
        margin-bottom: 50px;
    }

    .drop-header {
        margin: 40px 0;
    }
    
    .drop-header-title {
        font-size: 2.4em;
    }
    
    .drop-header-subtitle {
        font-size: 1.3em;
        font-weight: 600;
    }
    
    .drop-header-description {
        font-size: 1.1em;
        font-weight: 600;
    }

    .menu a {
        margin: 7px 10px 0;
    }

    .header-icons a {
        margin-top: 2px;
    }

    .drop-social-list {
        text-align: right;
    }

    .drop-social-list a {
        margin: 3px 18px;
        font-size: 0.9em;
    }

    .drop-social-list a svg {
        margin-right: 10px;
    }
}

@media (min-width: 1110px) {
    header, footer, main {
        max-width: 1110px;
    }

    footer {
        grid-template-columns: 750px 340px;
        padding-bottom: 30px;
    }

    .footer-social-list {
        text-align: left;
        margin-left: 10px;
    }

    .footer_copyright {
        text-align: right;
        margin-top: 6px;
    }

    .drop-header {
        margin: 45px 0;
    }
    
    .drop-header-title {
        font-size: 3.4em;
    }

    .drop-header-description {
        font-size: 1.5em;
    }

    .drop-introduction {
        padding-left: 25px;
        border-left: 3px solid var(--accentColor6);
        font-weight: 400;
        font-size: 1.3em;
        line-height: 1.1;
    }

    .drop-2-title {
        height: 400px;
        background-size: 1036px 400px;
    }
}