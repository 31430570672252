.wallet-contract-container {
    display: block;
    margin: 60px 0;
}

.icb-container-your-tokens span {
    color: var(--accentColor6);
    font-weight: 300;
}

.wallet-contract-container h2 {
    margin-bottom: 10px;
}

.icb-container-your-tokens h3 {
    margin: 10px 0;
}

.icb-container-your-tokens a {
    margin: 0 8px;
}

.wallet-address {
    display: block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wallet-disconnect {
    color: var(--accentColor6);
    cursor: pointer;
    font-size: 0.8em;
}

.wallet-disconnect:hover {
    text-decoration: underline;
}