.connect-button {
    display: inline-block;
    padding: 15px 25px;
    background-color: #146afb;
    margin: 0 10px;
    border-radius: 10px;
    color: #ffffff !important;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1em;
    cursor: pointer;
}

.connect-button:hover {
    background-color: #0d3ee5;
    color: #ffffff !important;
}

.paper-button-container {
    text-align: center;
}

.paper-button-container button {
    border: none;
    margin-left: auto;
    margin-right: auto;
}

.connect-component-container {
    margin: 60px auto 70px;
}

.connect-component-container h2 {
    margin-bottom: "15px";
}

.torus-link {
    color: #146afb;
}

.torus-link:hover {
    color: #0d3ee5;
}

.connect-component-torus-label {
    margin-top: 25px;
    font-size: 0.85em;
}