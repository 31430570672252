.new-artists-bottom h4 {
    font-weight: 400;
    font-size: 1.2em;
    line-height: 1;
    margin-bottom: 0px;
}

.new-artists-bottom h4 span {
    color: var(--accentColor6);
}

.new-artists-bottom p {
    font-size: 0.75em;
    margin-top: 15px;
    line-height: 1.4;
}

.new-artists-bottom p a {
    color: #000000;
    font-weight: 400;
    text-decoration: underline;
}

.new-artists-bottom p a:hover {
    text-decoration: none;
}

@media (min-width: 768px) {
    .new-artists-bottom {
        margin: 120px 0 100px;
    }

    .new-artists-bottom h4 {
        font-weight: 400;
        font-size: 2.2em;
        line-height: 1;
        margin-bottom: 10px;
    }

    .new-artists-bottom p {
        font-size: 1em;
        margin-top: 20px;
    }
}    